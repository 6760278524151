<template>
  <div class="edit-appointment">
    <appointmenTime
      :productId="HospitalProductId"
      @showCalendar="openCalendar"></appointmenTime>
    <calendar
      :hospitalProductId="HospitalProductId"
      :defaultDate="defaultDate"
      :showPopup="showCalendar"
      @onClosePopup="showCalendar = false"
      @onConfirm="onConfirmDate"></calendar>
    <orderInfo :formData.sync="formData" mode="view" />
    <div class="address">
      <p>
        <span>体检医院</span>
        <span class="right" style="flex: 1">{{ detail.hospitalName }}</span>
      </p>
      <p>
        <span>地址</span>
        <span class="right" style="flex: 1">{{ detail.address }}</span>
      </p>
    </div>
    <div class="buy-btn">
      <div class="detail-price">
        <van-button
          type="primary"
          round
          @click="handlerEdit"
          :disabled="!appointmentTime.time"
          >预约</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import calendar from "@/components/calendar.vue";
import appointmenTime from "@/views/components/goodDetail/appointmenTime.vue";

import orderInfo from "./components/orderInfo.vue";
import { ModifyOrderAppointment, GetOrderItem } from "@/api";
export default {
  name: "edit-appointment",
  components: {
    orderInfo,
    calendar,
    appointmenTime,
  },
  data() {
    return {
      formData: {},
      id: "",
      HospitalProductId: "",
      detail: {},
      appointmentTime: {},
      showCalendar: false,
      defaultDate: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.HospitalProductId = this.$route.query.hospitalProductId;
    this.$store.commit("setTitle", "预约改期");
    // this.$setTitle("预约改期");
    this.getDetail();
  },
  methods: {
    async handlerEdit() {
      // this.$router.push('/appointment-list')
      const params = {
        appointmentDate: this.appointmentTime.date,
        appointmentStartTime: this.appointmentTime.time.split("~")[0],
        appointmentEndTime: this.appointmentTime.time.split("~")[1],
        orderDetlId: this.detail.orderItems[0].orderDetlId,
      };
      await ModifyOrderAppointment(params);
      this.$router.replace("/order-detail/" + this.id);
    },
    async getDetail() {
      let data = await GetOrderItem({ OrderNo: this.id });
      this.detail = data;
      if (this.detail.orderItems.length) {
        this.detail.orderItems = this.detail.orderItems;
        this.formData.memberName = this.detail.orderItems[0].memberName;
        this.formData.certificateNo = this.detail.orderItems[0].certificateNo;
        this.formData.mobile = this.detail.orderItems[0].mobile;
        this.formData.birthday = this.detail.orderItems[0].birthday;
        this.formData.sex = this.detail.orderItems[0].sex == "1" ? "男" : "女";
        this.formData.maritalState =
          this.detail.orderItems[0].maritalState == "1" ? "已婚" : "未婚";
        this.formData.certificateOfType =
          this.detail.orderItems[0].certificateOfType == "1"
            ? "身份证"
            : "护照";
      }
      console.log(this.formData);
      this.formData = Object.assign({}, this.formData);
      this.$forceUpdate();
    },
    //打开预约日历
    openCalendar(date = {}) {
      this.defaultDate = date;
      this.showCalendar = true;
    },
    onConfirmDate(dateInfo) {
      this.appointmentTime = dateInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-appointment {
  background-color: #fff;
  padding: 10px 15px 80px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  .address {
    padding-top: 10px;
    font-size: 15px;
    color: #131828;
    p {
      display: flex;
      margin-bottom: 20px;
    }
  }
  .buy-btn {
    .van-button {
      width: 100%;
    }
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 53px;
}

.header-title {
  position: relative;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
  z-index: 2;
}

.header-desc {
  margin-left: 10px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #878d99;
}
.header-border {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.2;
  background: #08d794;
  border-radius: 0 100px 0 100px;
  height: 9px;
  width: 50px;
}

.icon-right {
  margin-left: 2px;
  width: 8px;
  height: 8px;
}

.buy-btn {
  position: fixed;
  bottom: 0;
  height: 16vw;
  display: flex;
  box-shadow: 0px 0px 3.466667vw 0px #d7d7d7;
  padding: 4vw;
  background: #fff;
  width: 100%;
  left: 0;
}
</style>
