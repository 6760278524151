<template>
  <div class="order-info-component">
    <div class="order-title">
      <div class="title-icon">
        <img src="@/assets/icon_img_tj@2x.png" alt="">
      </div>
      <span>体检人信息</span>
    </div>
    <div class="order-tip">
      <van-icon name="info-o" />
      <span>医院要求实名制体检，信息仅用于体检登记</span>
    </div>
    <form-box :formData.sync="formData1" :formList="formList" :mode="mode" @change="change" />
  </div>
</template>

<script>
import formBox from '@/components/formBox'
export default {
  name: 'order-info',
  components: {
    formBox
  },
  props: {
    formData: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      default: 'add'
    }
  },
  computed: {
    formData1: {
      get () {
        return this.formData
      },
      set (v) {
        this.$emit('update:formData', v)
      }
    }
  },
  data () {
    return {
      formList: [
        {
          type: 'Input',
          label: '体检人',
          prop: 'memberName',
          placeholder: '请输入体检人的真实姓名'
        },
        {
          type: 'Select',
          label: '证件类型',
          prop: 'certificateOfType',
          headerName: '证件类型',
          options: [
            {
              label: '身份证',
              value: '1'
            },
            {
              label: '护照',
              value: '2'
            }
          ]
        },
        {
          type: 'Input',
          label: '证件号码',
          prop: 'certificateNo',
          placeholder: '请输入证件号'
        },
        {
          type: 'Time',
          label: '出生日期',
          prop: 'birthday',
          placeholder: '请选择出生日期'
        },
        {
          type: 'RadioGroup',
          label: '性别',
          prop: 'sex',
          options: [{
            label: '男',
            value: '1'
          }, {
            label: '女',
            value: '2'
          }],
        },
        {
          type: 'Input',
          label: '手机号',
          prop: 'mobile',
          placeholder: '请输入手机号'
        },
        {
          type: 'RadioGroup',
          label: '婚姻状况',
          prop: 'maritalState',
          options: [{
            label: '已婚',
            value: '1'
          }, {
            label: '未婚',
            value: '2'
          }],
        },
        // {
        //   type: 'Select',
        //   label: '开票服务',
        //   prop: 'ticket',
        //   labelImg: require('@/assets/icon_img_kp@2x.png'),
        //   selectType: 'ticket',
        //   headerName: '开票服务',
        //   options: [
        //     {
        //       label: '平台开票',
        //       tipHTML: '<div>提供平台开具的电子发票，开票类型“体检费”，可正常报销。在体检完成后到【个人中心-预约记录-预约详情】中申请开票。</div>',
        //       value: '1'
        //     },
        //     {
        //       label: '医院开票',
        //       tipHTML: '<div>线上缴费不支持开医院发票，如需医院发票，<p class="primary">请前往线下购买！</p></div>',
        //       value: '2'
        //     }
        //   ]
        // },
      ],
    }
  },
  methods: {
    change (v, prop) {
      if (prop === 'certificateNo') {
        if (v.length === 18) {
          let birth = v.substr(6, 8).split('')
          birth.splice(4,0, '-')
          birth.splice(7,0, '-')
          birth = birth.join('')
          const sex = +v.substr(16, 1) % 2 === 1 ? '1' : '2'
          console.log(birth, sex)
          this.formData.birthday = birth
          this.formData.sex = sex
        }
      }
      console.log(v, prop)
    }
  }
}
</script>

<style lang="scss">
.order-info-component{
  .order-title{
    color: #131828;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 14px;
    .title-icon{
      display: inline-block;
      width: 14px;
      margin-right: 9px;
      vertical-align: middle;
    }
  }
  .order-tip{
    height: 28px;
    line-height: 28px;
    color: #00C4B3;
    background-color: #E5F9F7;
    border-radius: 10px;
    padding: 0 9px;
    .van-icon{
      margin-right: 6px;
    }
  }
  .van-field__label,.van-cell__title{
    font-size: 15px;
    color: #131828;
  }
  .van-cell{
    padding: 5px 0;
  }
}
</style>